/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Dropdown, Modal, Button, Icon, Menu } from "antd";
import { ENVSTORE, explicits, getExplicit } from "../../config";

export const EnvSelector = (props) => {
  const selectItem = (e) => {
    const isClear = e.key === 'clear';
    if (isClear) {
      localStorage.removeItem(ENVSTORE);
    } else {
      localStorage.setItem(ENVSTORE, e.key);
    }
    window.location.reload();
  }

  const options = [
    ...Object.keys(explicits).map(k => ({ title: k, key: k })),
    { title: 'clear', key: 'clear' }
  ]

  const menu = (
    <Menu onClick={selectItem}>
      {options.map(a => {
        return <Menu.Item key={a.key}>{a.title}</Menu.Item>
      })}

    </Menu>
  );

  return (
    <Modal title="Change ENV" {...props}>
      <div>
        <Dropdown overlay={menu}>
          <Button>
            {`ENV: ${getExplicit()}`} <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
    </Modal>
  );
};
