/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { Dropdown, Menu, Icon } from 'antd';
import { ellipsis as styles } from './header.styles';
import { connect } from 'react-redux';
import { getNameFromState, userIsLoggedIn } from '../../actions/utils';
import FeedbackButton from './feedbackbutton';
import { get } from 'lodash';
import Logo from '../../_shared/logo';
import * as React from 'react';
import { useScreen } from '../../_shared/hooks/useScreen';
import { getExplicit } from '../../config';

export const _Ellipsis = props => {
  const renderMenu = () => {
    const { changeRoute, routes } = props;

    return (
      <Menu css={css(styles.dropContainer)}>
        {routes.map((route, i) => {
          return (
            <Menu.Item
              key={i}
              css={css(styles.menuItem)}
              onClick={route.onClick ? route.onClick : () => changeRoute(route)}
            >
              {route.icon && (
                <span css={css(styles.menuIcon)}>
                  <Icon theme={'outlined'} {...route.icon} />
                </span>
              )}
              {route.title}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const { isMobile } = useScreen();
  const { sState } = props;
  const menu = renderMenu();
  const loggedIn = userIsLoggedIn(sState);

  const hasLogo = get(this, 'props.currentRoute.logo');

  const isExp = getExplicit();

  return (
    <div css={css(styles.container)}>
      {!!hasLogo && <Logo
        small={true}
        style={{ marginRight: 20 }}
        {...hasLogo}
      />}
      {!!loggedIn && <React.Fragment>
        <FeedbackButton />
        {!isMobile && <div>{getNameFromState(sState)}</div>}
        {!!isExp && <span style={{ marginLeft: 5, fontWeight: 'bold' }}>{`ENV: ${isExp}`}</span>}
        <Dropdown overlay={menu} trigger={['click']}>
          <a css={css(styles.a)} className="ant-dropdown-link" href="#">
            <Icon type="ellipsis" />
          </a>
        </Dropdown>
      </React.Fragment>}
    </div>
  );
}

export const Ellipsis = connect((state) => ({ sState: state }))(
  _Ellipsis,
);
