/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { withRouter, Route } from 'react-router';
import { Header } from './header';
import { SideMenu } from './side-menu';
import styles from './dashboard.styles';
import { filterBasedOnRoles, isDev, userIsVerfied } from '../actions/utils';
import { Routes } from './dashboard.routes';
import { InviteWatcher } from './invite/invitewatcher';
import { useView } from '../_shared/hooks/useView';
import { useScreen } from '../_shared/hooks/useScreen';
import { EnvSelector } from '../_shared/envselector';

export const DashboardComponent = props => {
  const [envModal, setEnvModal] = useState({
    visible: false,
    open: () => {
      setEnvModal(s => ({ ...s, visible: true }));
    },
    onCancel: () => {
      setEnvModal(s => ({ ...s, visible: false }))
    },
    onOk: () => {
      setEnvModal(s => ({ ...s, visible: false }))
    }
  })

  const switchEnv = () => {
    envModal.open();
  }

  const [state, setState] = useState({
    allRoutes: Routes,
    ellipsisRoutes: [
      Routes.account,
      Routes.changeEmail,
      Routes.changePassword,
      Routes.accountdelete,
      isDev() && {
        title: 'Switch Env',
        icon: { type: 'swap' },
        onClick: switchEnv
      },
      {
        title: 'Sign Out',
        icon: { type: 'unlock' },
        path: '/dashboard/account',
        onClick: props.logout,
      },
    ].filter(f => !!f),
    headerRoutes: [
      Routes.landing,
      Routes.admin
    ],
  });

  const { view, toggleDrawer } = useView();
  const { isMobile } = useScreen();

  useEffect(() => {
    props.retrieveData({ isMobile });
  }, []);

  const changeRoute = (route) => {
    if (isMobile) { toggleDrawer() };
    if (route.sideRoutes) {
      setState(s => ({ ...s, sideRoutes: route.sideRoutes }));
      if (route.path && route.path.indexOf('http') > -1) {
        return window.open(route.path, "_blank");
      }
      return props.history.push(route.path);
    }
    props.history.push(route.path);
  };
  
  const findRoute = () => {
    const { location } = props.history;
    let route;
    for (let key in Routes) {
      let r = Routes[key];
      if (r && r.path === location.pathname) {
        route = r;
        break;
      }
    }
    return route;
  }

  const { sState } = props;
  const { headerRoutes, ellipsisRoutes, allRoutes } = state;

  const isVerified = userIsVerfied(sState.user);

  const currentRoute = findRoute();

  const _headerRoutes = filterBasedOnRoles(headerRoutes, sState);
  const _sideRoutes = !isVerified ? [] : filterBasedOnRoles(
    currentRoute && currentRoute.sideRoutes,
    sState
  );
  const _ellipsisRoutes = filterBasedOnRoles(ellipsisRoutes, sState);

  const showDrawer = _sideRoutes.length > 0 && (isMobile ? view.drawerOpen : true);

  return (
    <Layout>
      <Header
        routes={_headerRoutes}
        ellipsisRoutes={_ellipsisRoutes}
        changeRoute={changeRoute}
        currentRoute={currentRoute}
      />
      <Layout>
        {showDrawer && (
          <SideMenu
            routes={_sideRoutes}
            currentRoute={currentRoute}
            changeRoute={changeRoute}
          />
        )}
        <Layout>
          <Layout.Content
            id={'ContentContainer'}
            css={css(styles.contentContainer)}
          >
            {Object.keys(allRoutes).map((key, i) => (
              <Route key={i} {...allRoutes[key]} />
            ))}
          </Layout.Content>
        </Layout>
      </Layout>
      <InviteWatcher />
      <EnvSelector {...envModal} />
    </Layout>
  );
}

export const Dashboard = withRouter(DashboardComponent);
