import { get, isObject, keyBy, size as _size, set, first, last, uniq } from 'lodash';
import v4 from 'uuid/v4';
import { getLicenseNumbers } from '../_shared/services/api.service';
import { getDrivers } from '../_shared/services/drivers.service';
import { sendManifest } from '../_shared/services/orders.service';
import { getRoutes } from '../_shared/services/routes.service';
import { getVehicles } from '../_shared/services/vehicles.service';
import { getAppConfig } from '../_shared/services/utils.service';
import moment from 'moment';
import { OrderStatus } from '../models/order';

export const defaultDateFormat = 'MMM D YYYY hh:mma';

export const sortByCreatedDate = (a, b) => {
  return (
    new Date(b.data.createdAt).getTime() - new Date(a.data.createdAt).getTime()
  );
};

const LABELS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const getLabelFromAlpha = i => {
  return LABELS[i];
}

export const sortByName = (a, b) => {
  if (a.name < b.name) { return -1; }
  if (a.name > b.name) { return 1; }
  return 0;
};

export const createPassword = () => {
  return 'MySecretPassword11!';
}

export const userIsVerfied = (user) => {
  return get(user, 'verified');
}

export const pullUserOutOfResp = (resp, opts = {}) => {
  const user = get(resp, 'data.user', {});
  const userData = get(resp, 'data.user.data', {});
  const removeEmail = get(opts, 'removeEmail', true);
  delete user.data;
  if (removeEmail) {
    delete userData.email;
  }
  delete userData.userId;
  return {
    ...userData,
    ...user,
  };
}

export const buildErrorMsgFromForm = (objErrors, str) => {
  let message = str || '';
  for (let key in objErrors) {
    let obj = objErrors[key];
    if(obj.errors) {
      let all = obj.errors;
      all && all.map(e => {
        message += e.message + '\n';
      });
    } else {
      message = buildErrorMsgFromForm(obj, message);
    }
  }
  return message;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const beforeImageUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    // eslint-disable-next-line no-undef
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    // eslint-disable-next-line no-undef
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

export const getContent = (content) => {
  return content();
};
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isLoggedIn = (sState) => {
  return get(sState, 'auth.user.sub');
};

export const inRange = (x, min, max) => {
  return (x - min) * (x - max) < 0;
};

export const convertHex = (hex, opacity) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
};

export const createHours = () => {
  const quarterHours = ['00', '15', '30', '45'];

  const hours = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      hours.push(('0' + i).slice(-2) + ':' + quarterHours[j]);
    }
  }

  return hours;
};

export const militaryToHours = (_time = '00:00') => {
  let time = _time.split(':');

  if (time.length < 2) {
    time = `${_time.slice(0, 2)}:${_time.slice(2)}`.split(':');
  }

  const hours = Number(time[0]);
  const minutes = Number(time[1]);
  const mins = minutes / 60;
  let timeValue = hours + mins;

  return timeValue;
};

export const testPassword = (password) => {
  var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
  return re.test(password);
};

export const uppercaseFirst = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.substring(1, str.length)}`;
};

export const PWReqsMessage =
  // tslint:disable-next-line:max-line-length
  'Please fix the password you provided. It must include at least one number, one lowercase and one uppercase letter. And be at least six characters.';

export const userHasRole = (id, sState) => {
  let has = null;
  // set(sState, 'user.role', 2);
  if (Array.isArray(id)) {
    for (let i = 0; i < id.length; i++) {
      let _id = id[i];
      if (sState.user && sState.user.role === _id) {
        has = true;
        break;
      }
    }
  } else {
    has = sState.user && sState.user.role === id;
  }
  return has ? true : false;
};

export const userIsSuspended = (sState) => {
  return get(sState, 'user.verified') === 'suspended';
}

export const getRoleName = (id, sState) => {
  const {
    auth: { allRoles },
  } = sState;
  const role = allRoles.find(role => role.id === id);
  return role ? role.title : 'none';
};

export const filterBasedOnRoles = (
  routes,
  sState
) => {
  return (routes || []).filter(route => {
    let allowed = true;
    
    if (route && route.allowedRoles) {
      allowed = false;
      for (let i = 0; i < route.allowedRoles.length; i++) {
        if (userHasRole(route.allowedRoles[i], sState)) {
          allowed = true;
          break;
        }
      }
    }

    if (route && route.restrictRoles) {
      for (let i = 0; i < route.restrictRoles.length; i++) {
        if (userHasRole(route.restrictRoles[i], sState)) {
          allowed = false;
          break;
        }
      }
    }

    return allowed;
  });
};

export const removeEmpty = (obj) => {
  for (let key in obj) {
    let o = obj[key];
    if (o === undefined || o === null || o.length === 0) {
      delete obj[key];
    }
    if (o !== null && typeof o === 'object') {
      o = o._isAMomentObject ? o.toISOString() : removeEmpty(o);
    }
  }
  return obj;
};

export const getNameFromState = (sState) => {
  const {
    user,
  } = sState;
  if (user) {
    const hasName = user.firstName && user.lastName;

    return hasName ? `${user.firstName} ${user.lastName}` : user.email;
  } else {
    return 'My Name';
  }
};

export const getUsersName = (user) => {
  return `${user.firstName} ${user.lastName}`.trim();
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const guid = () => {
  return v4();
};

export const uid = (length = 6) => {
  return parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""))
}

export const getUrlParams = (url) => {
  let match;
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s) {
    return decodeURIComponent(s);
  };
  const searchFor = '?';
  const index = url.indexOf(searchFor) + searchFor.length;
  const str = url.substring(index, url.length);
  const query = str;

  let urlParams = {};

  while ((match = search.exec(query)))
    urlParams[decode(match[1])] = decode(match[2]);

  return urlParams;
};

export const validPhoneNumber = (str) => {
  return phoneNumberReg().test(str);
};

export const phoneNumberReg = () => {
  return new RegExp(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  );
};

export const cleanCopy = (obj = {}) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (err) {
    console.warn(err);
    return obj;
  }
};

export const jparse = (str = '', fb = {}) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    console.warn(err);
    return fb;
  }
};

export const userIsLoggedIn = (sState = {}) => {
  return get(sState, 'user.id');
}

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const size = obj => {
  if (isObject(obj)) {
    return Object.keys(obj).length;
  } else {
    return _size(obj);
  }
}

export const stopProp = (e) => {
  e && e.stopPropagation && e.stopPropagation();
  e && e.preventDefault && e.preventDefault();
}

export const isLocal = process.env.NODE_ENV === 'development';

export const isDev = () => {
  const origin = get(window, 'location.origin', '');
  return !![
    'localhost', 
    'gps-env-dev',
    'gps-multi-dev',
    'dev.gpsorders.com'
  ].find(f => origin.indexOf(f) > -1);
}

export const getEnv = () => {
  const { host } = window.location;
  if (isLocal || host.includes('localhost')) {
    return 'local'
  } else if (host.includes('-dev')) {
    return 'dev';
  } else {
    return 'prod'
  }
}

window.KEYMAPS = {
  license: {},
  drivers: {},
  vehicles: {},
  routes: {},
};
const loadLicense = async () => {
  const license = await getLicenseNumbers(false);
  window.KEYMAPS.license = keyBy(license, 'License #');
};
export const loadLicenses = async () => {
  const [license, drivers, vehicles, routes] = await Promise.all([
    loadLicense(),
    getDrivers(),
    getVehicles(),
    getRoutes(),
  ]);
  // MAPS.license = keyBy(license, 'License #');
  window.KEYMAPS.drivers = keyBy(drivers, 'id');
  window.KEYMAPS.vehicles = keyBy(vehicles, 'id');
  window.KEYMAPS.routes = keyBy(routes, 'id');
};
window.LOADLICENSES = loadLicenses;

window.LOADLICENSE = loadLicense;

export const GPSID = '406R-00008';

export const getRawLicense = (key, ifblank) => {
  return get(window.KEYMAPS.license, key, ifblank);
}

export const getLicAddress = (key, include = ['Street Address', 'City', 'Zip']) => {
  const map = getRawLicense(key);

  return include.filter(i => !!get(map, i)).map(i => get(map, i)).join(', ');
}

export const getLicName = key => {
  const map = getRawLicense(key);

  const text = get(map, 'Licensee', '');
  const hasDBA = get(map, 'DBA');
  return hasDBA ? `${text} - ${hasDBA}` : text;
}

export const getDriver = id => {
  return get(window.KEYMAPS.drivers, id);
}

export const getDriverByEmail = email => {
  const id = Object.keys(window.KEYMAPS.drivers).find(k => {
    const driver = window.KEYMAPS.drivers[k];

    return get(driver, 'email', '').toLowerCase() == email.toLowerCase();
  })

  return getDriver(id);
}

export const getVehicle = id => {
  return get(window.KEYMAPS.vehicles, id);
}

export const getRoute = id => {
  return get(window.KEYMAPS.routes, id);
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const toMoney = num => {
  return formatter.format(num);
}

export const jformat = str => {
	return prettifyJson(str, true);
}
function prettifyJson(json, prettify) {
	if (typeof json !== 'string') {
			if (prettify) {
					json = JSON.stringify(json, undefined, 4);
			} else {
					json = JSON.stringify(json);
			}
	}
	return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
			function(match) {
					let cls = "<span>";
					if (/^"/.test(match)) {
							if (/:$/.test(match)) {
									cls = "<span class='text-danger'>";
							} else {
									cls = "<span>";
							}
					} else if (/true|false/.test(match)) {
							cls = "<span class='text-primary'>";
					} else if (/null/.test(match)) {
							cls = "<span class='text-info'>";
					}
					return cls + match + "</span>";
			}
	);
}

export const exportToCsv = (filename, rows) => {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      } else if (row[j] instanceof Object) {
        innerValue = get(row[j], 'name', '');
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const wait = (time = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  })
}

export const PageMedia = () => {
  var cssPagedMedia = (function () {
    var style = document.createElement('style');
    document.head.appendChild(style);
    return function (rule) {
        style.innerHTML = rule;
    };
  }());

  cssPagedMedia.size = function (size) {
    cssPagedMedia('@page { size: ' + size + '}');
  };

  cssPagedMedia.insert = function (str) {
    cssPagedMedia(`@page { ${str} }`);
  }

  return cssPagedMedia;
}

export const orderIsCheckedIn = order => {
  return !!get(order, 'data.order.checkedin')
}

export const orderIsCheckedOut = order => { //this is the client facing checkin
  return !!get(order, 'data.order.checkedout')
}

export const orderIsPickedUp = order => {
  return get(order, 'data.order.status') == OrderStatus.orderPickedUp;
}

export const orderIsRejected = order => { //this is the client facing checkin
  return get(order, 'data.order.rejected') !== undefined;
}

window.GETRAW = getRawLicense;
window.APPCONFIG = {};

export const loadConfig = async () => {
  const result = await getAppConfig();
  window.APPCONFIG = { ...(result || {}) };
  return result;
};

export const getConfig = (key, fb) => {
  return get(window.APPCONFIG, key, fb);
}

export const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac"))
}

export const copyToClipboard = (textToCopy) => {
  var textArea;

  function createTextArea(text) {
    textArea = document.createElement('textArea');
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    var range, selection;

    if (isIOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyTo() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  if (isIOS()) {
    createTextArea(textToCopy);
    selectText();
    copyTo();
  } else {
    copyToClipboardNotIos(textToCopy);
  }
}

export const copyToClipboardNotIos = text => {
  try {
    var copyText = document.createElement("textarea");
    document.body.appendChild(copyText);
    copyText.style = "display: inline; width: 1px; position: fixed; left: -999999px; top: -999999px;";
    copyText.value = text;
    copyText.focus();
    document.execCommand("SelectAll");
    document.execCommand("Copy");
    document.body.removeChild(copyText);
    if (get(navigator, 'clipboard.writeText')) {
      navigator.clipboard.writeText(text).then(() => 0);
    }
  } catch (err) {
    console.error(`Failed to copy text: ${err.message}`);
  }
};

export const orderHasManifest = order => {
  const isSigned = get(order, 'data.manifest.signed');
  const hasFile = get(order, 'data.manifest.filename');

  return {
    isSigned: !!isSigned,
    hasFile: !!hasFile,
    file: isSigned ? get(last(isSigned), 'filename') : hasFile,
  }
}

export const fileToBuffer = file => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = function(){ resolve(this.result) };
      fileReader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  })
}

export const uploadManifest = async (file, order, force = false) => {
  try {
    const buff = await fileToBuffer(file);
    await sendManifest({
      pdf: buff,
      currentWindow: window.location,
      order,
      force,
    });
  } catch (err) {
    const error = get(err, 'response.data.error', err.message);
    throw new Error(error);
  }
}

export const uploadManifestWithConfirm = async (file, order) => {
  try {
    await uploadManifest(file, order);
  } catch (err) {
    const error = get(err, 'response.data.error', err.message);

    if (error.toLowerCase().includes('licenses do not match')) {
      const cont = window.confirm('The licenses do not match. Are you sure you want to upload this manifest? It will overwrite any existing manifests.')
      if (cont) {
        return uploadManifest(file, order, true)
      } else {
        throw new Error('Upload cancelled.')
      }
    }
    throw new Error(error);
  }
}

export const isNoShowRoute = (route = {}) => {
  const zip = get(route, 'zipCode');
  const name = get(route, 'name', '').toLowerCase().split(' ').join('');
  return zip == '00001' || name == 'noshoworders';
}

window.ISDEV = isDev;

export const DateTool = {
  // moment,
  markerDate: () => {
    return moment('2023-01-01');
  },
  dayStringToNum: (d) => {
    const days = {
      sun: 0,
      mon: 1,
      tues: 2,
      wed: 3,
      thurs: 4,
      fri: 5,
      sat: 6,
    }

    return days[d];
  },
  generateArray: function (type = 'all') {
    const start = this.markerDate(); //sun jan 01 2023

    const arr = [];

    // 0 is sunday
    const validdays = [1, 2, 3, 4, 5];
    // const validdays = [0, 6];

    for (let i = 0; i < 365; i++) {
      const day = moment(start).add(i, 'day');
      if (validdays.indexOf(day.day()) > -1) {
        arr.push(i);
      }
    }

    return arr;
  },
  dateToNum: function (date) {
    return moment(moment(date)).diff(this.markerDate(), 'days');
  },
  getDate: function (addDay) {
    return moment(this.markerDate()).add(addDay, 'days');
  },
  calToDate: function (obj) {
    const { day, month, year } = obj;

    const d = moment(`${year}-${month}-${day}`);

    const diff = d.diff(this.markerDate(), 'days');

    return diff;
  },
  isDateArray: function (d) {
    return Array.isArray(d) && typeof first(d) === 'number';
  },
  dateToCal: function (d) {
    const date = this.getDate(d).format('YYYY-MM-DD');
    const [year, month, day] = date.split('-').map(v => parseInt(v));
    return { day, month, year };
  },
  arrToDates: function (arr, format) {
    return arr.map(a => {
      const d = this.getDate(a);
      if (format) {
        return d.format(format);
      }
      return d;
    })
  },
  dateInArr: function (date, arr) {
    date = moment(date);
    const diff = date.diff(this.markerDate(), 'days');

    return arr.indexOf(diff) > -1;
  },
}

window.DATETOOL = DateTool;

window.MAPS = {};
window.GEO = {};
window.DIRSERVICE = {};

export const getGeoCode = (opts) => {
  return new Promise((resolve, reject) => {
    window.GEO.geocode(opts, function(results, status) {
      if (status == 'OK') {
        resolve(first(results));
      } else {
        reject(status);
      }
    });
  })
}

export const getDirections = async (opts) => {
  return new Promise((resolve, reject) => {
    window.DIRSERVICE.route(opts, function(results, status) {
      if (status == 'OK') {
        resolve(results);
      } else {
        reject(status);
      }
    });
  })
}

export const makeMap = (elem, opts = {}) => {
  opts = {
    zoom: 10,
    center: new window.google.maps.LatLng(2.8, -187.3),
    mapTypeId: "roadmap",
    ...opts,
  }
  return new window.MAPS(elem, opts);
}

export const makeMarker = (opts = {}) => {
  return new window.google.maps.Marker(opts);
}

export const makeDirService = () => {
  return new window.google.maps.DirectionsService();
}

export const makeDirRenderer = (opts = {}) => {
  return new window.google.maps.DirectionsRenderer(opts);
}

async function initMap() {
  const { Map } = await window.google.maps.importLibrary("maps");
  window.GEO = new window.google.maps.Geocoder();
  window.MAPS = Map;
  window.DIRSERVICE = makeDirService();
}
initMap();

export const pickFrom = (obj, arr) => {
  const newobj = {};
  arr.map(k => {
    set(newobj, k, get(obj, k));
  })
  return newobj;
}

export const getManifestUrl = filename => {
	if (!filename) { return undefined };
	return `https://gps-manifests.s3.us-west-2.amazonaws.com/${filename}`;
}

export const getCopiesUrl = filename => {
	if (!filename) { return undefined };
	return `https://gps-copies.s3.us-west-2.amazonaws.com/${filename}`;
}

export const getFileUrl = filename => {
	if (!filename) { return undefined };
	return `https://gps-one-uploads.s3.us-west-2.amazonaws.com/${filename}`;
}

export function mergeDeep(...objects) {
  const isObject = obj => obj && typeof obj === 'object';
  
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];
      
      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = oVal;
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });
    
    return prev;
  }, {});
}

export const getAllLnsFromUser = user => {
  const main = get(user, 'company.License #');
  const others = get(user, 'company.licenseNumbers', []).map(l => (l || '').trim()).filter(f => !!f);
  return { main, all: uniq([main, ...others]) }
}

export const saveWorksheet = (filename, ws) => {
  var blob = new Blob([ws], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
