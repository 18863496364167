/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component } from 'react'
import { message, Icon, DatePicker, Button, Input, Checkbox } from 'antd';
import { get, first, set, orderBy, flatten, uniqBy, drop } from 'lodash';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import styles from './schedule.styles';
import { getSchedule, mergePdfs, signManifest, updateOrder } from '../../_shared/services/orders.service';
import { TableList, TableListHeader } from '../../_shared/table-list';
import Link from '../../_shared/link';
import { DriverOrderButton, OrderModal, AdminOrderButton } from '../orders/orders.component';
import { filterSchedule } from '../billing/billing.component';
import { getConfig, getLicAddress, getLicName, getRawLicense, GPSID, isNoShowRoute, jparse, orderIsRejected, size, userHasRole, getUrlParams, orderHasManifest, getManifestUrl, getCopiesUrl } from '../../actions/utils';
import { awaitAll } from '../../actions/awaitAll';
import colors from '../../_shared/colors';
import { CashPickupIcon, CashReturnIcon } from '../../_shared/CustomIcons';
import { getDataFromGuid } from '../../_shared/driver-search';
import ScreenComponent from '../../_shared/hooks/useScreen';
import Row from '../../_shared/row';
import { CHECKINICON, OrderStatus, getStatusIcon } from '../../models/order';
import { MapModal } from '../map';

const isCashReturn = (o) => {
  return get(o, 'data.order.orderType') === 'cash_return';
}

export const getOrderTypeBasedOnDay = (order, date) => {
  const isPickup = get(order, 'data.order.pickupDate') && moment(get(order, 'data.order.pickupDate')).isSame(date, 'day');
  return isPickup ? 'pickup' : 'delivery';
}

export const getCols = (_this) => {
  const isMobile = get(_this, 'props.screen.isMobile');
  const isDriver = userHasRole(2, _this.props.sState);

  let rows = [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      width: isMobile ? 100 : 180,
      order: 0,
      fixed: get(_this, 'state.isPrint') ? undefined : 'left',
      render: (t, row) => {
        const order = get(row, '__order', row);
        const checkedin = !!get(row, 'data.order.checkedin');
        const isCashPickup = get(order, 'data.order.orderType') === 'cash_pickup';
        const isCashReturnLeg = _this.isCashReturnLeg(order);
        const isCashReturn = isCashReturnLeg || get(order, 'data.order.cashReturn');
        const isRejected = orderIsRejected(order);
        const hasNotes = !!get(row, 'data.order.notes');
        const statico = getStatusIcon(row);
        const otype = !_this.notCashReturn(order) ? 'delivery' : getOrderTypeBasedOnDay(row, _this.state.selectedDate)
        const { driver } = getDataFromGuid(get(row, `data.order.driver.${otype}`));
        const { hasFile } = orderHasManifest(row);
        
        return (
          <div>
            <Link onClick={() => _this.selectOrder(order, row)}>
              <span style={isRejected ? { color: colors.error, fontWeight: 'bold' } : checkedin ? { color: colors.highlight, fontWeight: 'bold' } : {}}>
                {t}
                {statico && <Icon type={statico.icon} style={{ color: statico.color, marginLeft: 2 }} title={statico.title} />}
                {isCashReturn && <CashReturnIcon />}
                {isCashPickup && !isCashReturn && <CashPickupIcon />}
                {checkedin && <Icon type={CHECKINICON} style={{ marginLeft: 2 }} />}
                {hasNotes && <Icon type="tag" title="Order has notes" style={{ marginLeft: 2 }} />}
                {hasFile && <Icon type="file-protect" title="Order has manifest uploaded" style={{ marginLeft: 2 }} />}
              </span>
            </Link>
            {!isDriver && !_this.isDrop && <span>{get(driver, 'name')}</span>}
          </div>
        )
      }
    },
    {
      title: isMobile ? 'Quantity/\nNotes' : 'Quantity/Notes',
      dataIndex: 'data.order.notes',
      width: isMobile ? undefined : '15%',
      order: 10,
    },
    {
      title: 'Vendor',
      dataIndex: 'data.pickupCompany.Licensee',
      width: isMobile ? '15%' : '20%',
      order: 1,
      render: (t, row) => {
        const key = get(row, 'data.order.pickupLN');
        const comp = getRawLicense(key);
        const addr = getLicAddress(key);
        const compName = get(comp, `DBA`) || get(comp, `Licensee`) || '';
        const text = `${compName || ''}${compName ? ' - ' : ''}${addr}`;
        const isCashReturnLeg = _this.isCashReturnLeg(row) || orderIsRejected(row);

        if (isCashReturnLeg) {
          return <span>{getLicName(GPSID)}</span>
        }

        return <span>{text}</span>
      }
    },

    //#region reports only stuff
    {
      title: 'Destination Name',
      dataIndex: 'data.destCompany.Street Address',
      width: '14%',
      show: !!_this.isReport,
      value: (row) => {
        const isPickup = get(row, 'isPickup');
        const key = get(row, `data.order.${isPickup ? 'pickupLN' : 'destinationLN'}`);
        const { Licensee } = getRawLicense(key, { DBA: '' });

        //Reports uses this
        return `${Licensee}`;
      },
    },
    {
      title: 'DBA',
      dataIndex: 'data.destCompany.Street Address',
      width: '14%',
      show: !!_this.isReport,
      value: (row) => {
        const isPickup = get(row, 'isPickup');
        const key = get(row, `data.order.${isPickup ? 'pickupLN' : 'destinationLN'}`);
        const { DBA,  } = getRawLicense(key, { DBA: '' });

        //Reports uses this
        return DBA || '';
      },
    },
    {
      title: 'Destination Address',
      dataIndex: 'data.destCompany.Street Address',
      width: '14%',
      show: !!_this.isReport,
      value: (row) => {
        const isPickup = get(row, 'isPickup');
        const key = get(row, `data.order.${isPickup ? 'pickupLN' : 'destinationLN'}`);

        const address = getLicAddress(key, ['Street Address', 'City']);

        //Reports uses this
        return address || '';
      },
    },
    {
      title: 'Destination Zip',
      dataIndex: 'data.destCompany.Street Address',
      width: '14%',
      show: !!_this.isReport,
      value: (row) => {
        const isPickup = get(row, 'isPickup');
        const key = get(row, `data.order.${isPickup ? 'pickupLN' : 'destinationLN'}`);

        const map = getRawLicense(key);
        const zip = get(map, 'Zip');

        //Reports uses this
        return zip || '';
      },
    },
    {
      title: 'Destination License #',
      dataIndex: 'data.destCompany.Street Address',
      width: '14%',
      show: !!_this.isReport,
      value: (row) => {
        const isPickup = get(row, 'isPickup');
        const key = get(row, `data.order.${isPickup ? 'pickupLN' : 'destinationLN'}`);

        return key || '';
      },
    },
    //#endregion

    {
      title: 'Destination',
      dataIndex: 'data.destCompany.Street Address',
      width: isMobile ? '15%' : '20%',
      order: 2,
      show: !_this.isReport,
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const islayover = get(row, 'data.order.pickupType') === 'layoverpickup';
        const isCashReturnLeg = _this.isCashReturnLeg(row);
        const key = isPickup && islayover ? GPSID : get(row, `data.order.${isPickup ? 'pickupLN' : 'destinationLN'}`);

        const { DBA, Licensee } = getRawLicense(key, { DBA: '' });

        const address = getLicAddress(key);

        const text = `${Licensee}${DBA ? ` - ${DBA} - ` : ' - '}${address}`;
        
        return <span>{text}</span>
      }
    },
    {
      title: 'City',
      dataIndex: 'data.destCompany.City',
      order: 3,
      show: !isDriver,
      render: (t, row) => {
        const isPickup = get(row, 'isPickup');
        const key = isPickup || _this.isCashReturnLeg(row) ? get(row, 'data.order.pickupLN') : get(row, 'data.order.destinationLN');
        const comp = getRawLicense(key);
        return <span>{get(comp, 'City') || ''}</span>
      }
    },
    //driver blanks
    {
      title: 'Invoice',
      dataIndex: 'data.billing.invoice',
      width: isMobile ? undefined : '7%',
      order: 4,
    },
    {
      title: 'Amount',
      dataIndex: 'data.billing.amount',
      width: isMobile ? undefined : '7%',
      order: 5,
    },
    {
      title: 'Method',
      dataIndex: 'data.billing.method',
      width: isMobile ? undefined : '7%',
      order: 6,
    },
    {
      title: 'Manifest',
      dataIndex: 'data.billing.manifest',
      width: isMobile ? undefined : '7%',
      order: 7,
    },
    {
      title: 'Box Count',
      dataIndex: 'data.order.weight',
      width: isMobile ? undefined : '7%',
      order: 8,
    },
    {
      title: 'GPS Invoice',
      dataIndex: 'data.billing.gpsinvoice',
      width: isMobile ? undefined : '7%',
      show: !isDriver && get(_this, 'state.showExtras', false),
      order: 9,
    },
    {
      title: 'Paperwork',
      dataIndex: 'data.billing.gpsinvoice',
      width: isMobile ? undefined : '7%',
      show: !isDriver && !get(_this, 'state.showExtras', false),
      order: 10,
      render: (t, row) => {
        const key = _this.getPaperDateKey();
        return <Checkbox checked={!!get(row, `data.order.paper.${key}`)} onChange={v => _this.changePaperVal(row, v)} />
      }
    },
  ].filter(f => get(f, 'show', true));

  if (isDriver) {
    rows = orderBy(rows, 'order');
  }

  return rows;
}

const order = [
  OrderStatus.orderReceived,
  OrderStatus.orderApproved,
  OrderStatus.orderPickedUp,
  OrderStatus.inTransit,
  OrderStatus.delivered,
  OrderStatus.exception,
  OrderStatus.fullrejection,
  OrderStatus.partialrejection,
]

const sortByDest = (a, b) => {
  const aval = order.indexOf(get(a, 'data.order.status', 1));
  const bval = order.indexOf(get(b, 'data.order.status', 1));
  const adest = get(a, 'data.order.destinationLN', '');
  const bdest = get(b, 'data.order.destinationLN', '');

  if (aval == bval) { return adest == bdest ? 0 : adest.localeCompare(bdest, 'en', { numeric: true }) };

  return aval - bval;
  // const aisPickup = get(a, 'isPickup');
  // const bisPickup = get(b, 'isPickup');
  // const ad = get(a, `data.${aisPickup ? 'pickupCompany' : 'destCompany'}.DBA`, '');
  // const bd = get(b, `data.${bisPickup ? 'pickupCompany' : 'destCompany'}.DBA`, '');
  // return ad.localeCompare(bd, 'en', { numeric: true });
}

const sortByDestAndCash = (a, b) => {
  const aiscash = isCashReturn(a);
  const biscash = isCashReturn(b);

  if (aiscash) {
    return 1;
  } else if (biscash) {
    return -1;
  } else if (aiscash && biscash) {
    return 0;
  }

  return sortByDest(a, b);
}

const getDate = d => {
  return moment(d);
}

export class ScheduleComponent extends Component {
  state = {
    loadingSchedule: false,
    selectedDate: getDate(),
    originalSchedule: {},
    schedule: {},
    today: getDate(),
    printOnly: undefined,
    editModal: undefined,
    isPrint: false,
    isAdmin: window.location.href.includes('/manageSchedule'),
    map: {},
    showExtras: false,
    paperLoad: {},
  }
  getPaperDateKey = () => {
    return moment(get(this, 'state.selectedDate')).format('YY-MM-DD');
  }
  changePaperVal = async (row, e) => {
    try {
      const val = !!get(e, 'target.checked');
      set(row, `data.order.paper.${this.getPaperDateKey()}`, val);
      await updateOrder(row);
    } catch (err) {
      message.error(err.toString());
    }
  }
  componentDidMount = () => {
    this.loadSchedule();

    const { id } = getUrlParams(window.location.href);
    if (!!id) {
      this.selectOrder({ id });
    }
  }
  onSelectDay = (date) => {
    this.setState({ selectedDate: date ? getDate(date) : null }, this.loadSchedule);
  }
  loadSchedule = async () => {
    try {
      const { selectedDate, isAdmin } = this.state;
      if(!selectedDate) return;

      this.setState({ loadingSchedule: true });
      const day = getDate(selectedDate).startOf('day').add(6, 'hours').toISOString();
      const schedule = await getSchedule({ day, isAdmin });

      for (let k in schedule) {
        const { orders } = schedule[k];

        set(schedule, [k, 'orders'], this.filterOrders(orders));
      }
      
      this.setState({ loadingSchedule: false, schedule, originalSchedule: schedule });
    } catch (err) {
      message.error(`Error: ${err.message}`);
      this.setState({ loadingSchedule: false, schedule: {}, originalSchedule: {} });
    }
  }
  isCashReturnLeg = (order) => {
    const isCashPickup = get(order, 'data.order.orderType') === 'cash_pickup';
    return isCashPickup && this.isSameDate(order, 'data.order.deliveryDate');
  }
  isSameDate = (o, sel) => {
    const compDate = get(o, sel);
    return !compDate ? false :  getDate(compDate).isSame(getDate(this.state.selectedDate), 'day');
  }
  filterOrders = (orders, byType, opts = {}) => {
    opts = {
      driver: null,
      ...opts,
    }

    if (userHasRole(2, this.props.sState) || !!opts.driver) {
      const thisdriver = opts.driver || get(this, 'props.sState.user.driver');

      if (!thisdriver) {
        return [];
      }

      const did = get(thisdriver, 'id');

      return orders.filter(o => {
        const odriver = get(o, 'data.order.driver');
        const route = jparse(get(o, 'data.order.route'));

        if (!odriver) { return false; }

        const deldriver = getDataFromGuid(odriver.delivery);
        const pickupdriver = getDataFromGuid(odriver.pickup);

        const matchesdel = get(deldriver, '_driver') == did;
        const matchespick = get(pickupdriver, '_driver') == did;
        const isNoShow = isNoShowRoute(route);

        if (isNoShow) { return false; }
        
        if (byType) {
          if (byType === 'delivery') {
            return matchesdel;
          } else if (byType === 'pickup') {
            return matchespick;
          }
        }
        
        return matchesdel || matchespick;
      })
    }
    
    return orders;
  }
  pulloutPickups = () => {
    const { schedule } = this.state;
    
    const pickups = {};

    Object.keys(schedule).map(k => {
      const { orders } = schedule[k];
      orders.map(o => {
        const otype = get(o, 'data.order.orderType');
        const proute = get(o, 'data.order.pickupRoute')

        const pr = jparse(proute);
        if (get(pr, 'zipCode') == '00000' && get(pr, 'city', '').indexOf('Utility') === -1 && proute && this.isSameDate(o, 'data.order.pickupDate')) {
          set(pickups, pr.city, [
            ...get(pickups, pr.city, []),
            o
          ])
        } else if (otype === 'dropoff' && this.isSameDate(o, 'data.order.dropoffDate')) {
          const sel = 'Warehouse Drop';
          set(pickups, sel, [
            ...get(pickups, sel, []),
            o,
          ])
        }
      })
    })

    return pickups;
  }
  notCashReturn = o => {
    return get(o, 'data.order.orderType') !== 'cash_return';
  }
  pullOutPickupTypes = (orders) => {
    return {
      regular: orders.filter(o => {
        const ptype = get(o, 'data.order.pickupType') === 'regular' || (!get(o, 'data.order.pickupType') && get(o, 'data.order.orderType') !== 'dropoff' && get(o, 'data.order.orderType') !== 'cash_return');
        return ptype && this.isSameDate(o, 'data.order.deliveryDate') && this.notCashReturn(o);
      }),
      warehouse: this.filterOrders(orders, 'delivery').filter(o => {
        const ptype = get(o, 'data.order.pickupType');
        const otype = get(o, 'data.order.orderType');
        const isLayover = (ptype === 'warehouse' || ptype === 'layoverpickup' || (otype === 'dropoff' && !ptype) || otype === 'cash_return') && this.isSameDate(o, 'data.order.deliveryDate');
        return isLayover && this.notCashReturn(o);// || get(o, 'data.order.pickupType') === 'warehouse';
      }),
      // layover: orders.filter(o => {
      //   return get(o, 'data.order.pickupType') === 'layoverpickup' && moment(get(o, 'data.order.deliveryDate')).isSame(this.state.selectedDate, 'day');
      // }),
      layoverpickup: this.filterOrders(orders, 'pickup').filter(o => {
        return get(o, 'data.order.pickupType') === 'layoverpickup' && this.isSameDate(o, 'data.order.pickupDate') && this.notCashReturn(o);
      }).map(o => ({ __order: { ...o }, ...o, route: jparse(get(o, 'data.order.pickupRoute')), isPickup: true })),
      dropoff: (() => {
        return orders.filter(o => get(o, 'data.order.orderType') === 'dropoff' && this.isSameDate(o, 'data.order.dropoffDate'));
      })(),
      cashreturn: orders.filter(o => !this.notCashReturn(o)),
      cashpickup: orders.filter(o => get(o, 'data.order.orderType') === 'cash_pickup'),
    }
  }
  passesIgnore = (key) => {
    const ignore = ['Cash', 'Warehouse'];
    return size(ignore.filter(ig => key.indexOf(ig) > -1)) === 0;
  }
  getScheduleMap = () => {
    const { schedule } = this.state;

    const pickups = this.pulloutPickups();
    const warehouses = Object.keys(pickups).map(k => pickups[k]).map(o => o.id);

    const sched = {
      DROPS: {
        route: { name: 'Dropoffs' },
        orders: [],
      }
    };

    Object.keys(schedule).map(k => {
      const item = schedule[k];
      const { layoverpickup, regular, warehouse, dropoff } = this.pullOutPickupTypes(item.orders);

      const routekey = 'route.id';
      const schedkey = get(item, routekey, k);

      const layids = layoverpickup.map(lo => lo.id);
      const lps = [...layoverpickup, ...regular, ...warehouse];
      if (size(lps)) {
        layoverpickup.filter(lp => warehouses.indexOf(lp.id) === -1).map(lo => {
          const routename = get(lo, routekey, 'Default');
          const sel = routename;
          const ordsel = `${sel}.orders`;
          set(sched, [sel, 'route'], lo.route);
          set(sched, [sel, 'isPickup'], true);
          set(sched, ordsel, [
            ...get(sched, ordsel, []),
            lo,
          ])
        })
      
        const otherorders = get(sched, [schedkey, 'orders'], []);
        const orders = [...otherorders, ...item.orders];
        sched[schedkey] = { 
          ...item, 
          orders: orders.filter(o => layids.indexOf(o.id) === -1) //we are filtering out layoverpickups here, for some reason it is removing some leaflink orders
        }
      }

      if (size(dropoff)) {
        const key = 'DROPS.orders';
        set(sched, key, [...get(sched, key, []), ...dropoff])
      }
    })

    return sched;
  }
  selectOrder = (editOrder, row) => {
    const cashReturn = !this.notCashReturn(editOrder);
    this.setState({
      editModal: {
        isModal: true,
        state: {
          cashReturn,
          isPickup: get(row, 'isPickup', false),
          editOrder,
        }
      }
    });
  }
  orderFromSchedule = (sched, cashReturn = false) => {
    this.props.history.push('/dashboard/adminEditOrder', {
      isNewAdminOrder: true,
      cashReturn,
      editOrder: cashReturn ? {} : {
        data: {
          ...get(first(sched.orders), 'data', {}),
        }
      },
    })
  }
  renderWarehouse = () => {
    const { loadingSchedule, selectedDate, printOnly, isPrint } = this.state;

    const pickups = this.pulloutPickups();

    if (!size(pickups) || loadingSchedule) { return null };

    const tProps = {
      implementScroll: false,
      columns: getCols(this),
      showPagination: false,
      canSelectRows: false,
      scroll: isPrint ? undefined : { x: 1000 }
    }

    return (
      <div css={css(styles.routeDivider)} className={printOnly && printOnly.indexOf('__warehouse__') === -1 ? 'no-print printNoMarg' : 'printNoMarg'}>
        <TableListHeader css={css(styles.routeTableHeader)}>
          <div css={css(styles.routeHeader)}>{`Warehouse - ${getDate(selectedDate).format('L')}`}</div>
        </TableListHeader>

        {Object.keys(pickups).map(k => {
          const orders = pickups[k];
          return (
            <div className={printOnly && printOnly !== `${k}__warehouse__` ? 'no-print printNoMarg' : "printNoMarg"} css={css(styles.margB10)}>
              <TableListHeader>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{k}</div>
                <div css={css(styles.printOnly)}>
                  <Button
                    onClick={() => this.setState({ printOnly: `${k}__warehouse__` }, this.print)}
                    css={css(styles.printOnly)}
                    className="no-print"
                    type="primary"
                    icon="printer"
                  />
                </div>
              </TableListHeader>
              <TableList {...tProps} data={orders.sort(sortByDest)} />
            </div>
          )
        })}
      </div>
    )
  }
  setupMap = async (items) => {
    const schedule = this.getScheduleMap();

    const allOrders = flatten(Object.keys(schedule).map(k => get(schedule, [k, 'orders'], [])));

    const key = 'data.order.driver.delivery';
    const _driver = get(items.find(item => get(item, key)), key);
    const driver = _driver ? getDataFromGuid(_driver).driver : null;

    const driverOrders = this.filterOrders(allOrders, false, { driver });

    const LNS = {};
    
    const addresses = orderBy(uniqBy(driverOrders.map(item => {
      const dln = get(item, 'data.order.destinationLN');
      const pln = get(item, 'data.order.pickupLN');

      const { delivery } = get(item, 'data.order.driver', {});

      const isDelivery = getDataFromGuid(delivery)._driver === get(driver, 'id') ? true : false;

      const ln = isDelivery ? dln : pln;

      set(LNS, ln, get(LNS, ln, 0) + 1);

      return {
        name: getLicName(ln),
        address: getLicAddress(ln),
        ln,
        isDelivery,
      };
    }), 'ln').map(item => {
      const count = get(LNS, item.ln, 0);

      const isPlur = count > 1;
      const suffix = `${item.isDelivery ? isPlur ? 'deliveries' : 'delivery' : isPlur ? 'pickups' : 'pickup'}`;
      const raw = getRawLicense(item.ln);

      const name = [
        get(raw, 'City'),
        get(raw, 'Zip'),
        item.name,
        `${count} ${suffix}`
      ];

      return {
        ...item,
        zip: get(raw, 'Zip'),
        name: name.filter(n => !!n).join(' - '),
      }
    }), 'zip');

    const dateid = this.state.selectedDate.startOf('day').toISOString();

    this.setState({ 
      map: {
        id: `${driver.id}_${dateid}`,
        addresses,
      }
    });
  }
  signManifests = async (items) => {
    try {
      this.setState({ signing: true });
      const haveManifests = items.filter(item => get(item, 'data.manifest'));

      const signature = get(this.props, 'sState.user.signature');

      if (!signature) {
        throw new Error('Please setup a signature before you use this');
      }

      let totalSigned = 0;

      const wrongStats = [OrderStatus.delivered, OrderStatus.exception, OrderStatus.fullrejection, OrderStatus.partialrejection];
      const ordersNotRightState = haveManifests.filter(o => wrongStats.includes(get(o, 'data.order.status')));

      if (size(ordersNotRightState)) {
        const cont = window.confirm('Some of these orders have already been delivered or rejected. Are you sure you want to put these orders back into In-Transit.');
        if (!cont) {
          throw new Error('Sign manifests canceled.');
        }
      }

      await awaitAll(haveManifests.map(order => () => async () => {
        set(order, 'data.order.signature.driver', { ...signature, date: new Date().toISOString() });
        set(order, 'data.order.status', OrderStatus.inTransit);
        await updateOrder(order);
        await signManifest({ order });
        totalSigned += 1;
      }), { queueSize: 2 });

      message.success(`${totalSigned} manifest(s) out of ${size(items)} were signed`);
    } catch (err) {
      message.error(err.message);
    } finally {
      this.setState({ signing: false });
    }
  }
  renderSchedule = () => {
    const { loadingSchedule, printOnly, selectedDate, isPrint } = this.state;
    
    const isMobile = get(this, 'props.screen.isMobile');

    const schedule = this.getScheduleMap();

    if (loadingSchedule) {
      return <Icon type="loading" />
    }

    if (size(schedule) === 0) {
      return <div css={css(styles.noOrders)}>No Orders found. Please select a different date.</div>
    }

    const isDriver = userHasRole(2, this.props.sState);

    return (
      <div>  
        {Object.keys(schedule).map(k => {
          const { regular, warehouse, layoverpickup, cashreturn, dropoff } = this.pullOutPickupTypes(get(schedule, [k, 'orders']));

          if (!this.passesIgnore(k)) { return null }

          const isDrop = k === 'DROPS';

          if (isDrop && isDriver) { return null }

          const amt = isDrop ? dropoff : [
            ...regular,
            ...warehouse,
            ...layoverpickup,
            ...cashreturn,
            // ...cashpickup,
          ]

          const tProps = {
            implementScroll: false,
            columns: getCols({ ...this, isDrop }),
            showPagination: false,
            canSelectRows: false,
            scroll: isPrint ? undefined : { x: isMobile ? 850 : 1000 }
          }

          const sched = get(schedule, k);

          const mapsEnabled = !!getConfig('scheduleMapsEnabled');

          const count = {
            del: size(amt.filter(o => getOrderTypeBasedOnDay(o, selectedDate) === 'delivery')),
            pick: size(amt.filter(o => getOrderTypeBasedOnDay(o, selectedDate) === 'pickup')),
          }

          return !size(amt) ? null : (
            <div key={k} css={css(styles.routeDivider)} className={printOnly && printOnly !== k ? 'no-print printNoMarg' : 'printNoMarg'}>
              <TableListHeader css={css(styles.routeTableHeader)}>
                <div css={css(styles.routeHeader)}>{`${get(sched, ['route', 'name'], '')} - ${getDate(selectedDate).format('L')}`}</div>
                <Row css={css(styles.printOnly, `width: auto;`)}>
                  <Button
                    onClick={() => this.setState({ printOnly: k }, this.print)}
                    css={css(styles.printOnly)}
                    className="no-print"
                    type="primary"
                    icon="printer"
                  />

                  <Button
                    onClick={() => this.printManifests(warehouse)}
                    css={css(styles.printOnly)}
                    className="no-print"
                    type="primary"
                    title="Print Manifests"
                  >
                    <Icon type="file-protect" />
                    <Icon type={this.state.isPrintManifests ? "loading" : "printer"} />
                  </Button>

                  {mapsEnabled && <Button
                    onClick={() => this.setupMap(amt)}
                    css={css(styles.printOnly)}
                    className="no-print"
                    type="primary"
                    icon="compass"
                  />}

                  {getConfig('sigs.show') && isDriver && <Button
                    onClick={() => this.signManifests(warehouse)}
                    css={css(styles.printOnly)}
                    className="no-print"
                    type="primary"
                    icon="edit"
                    title="Sign Manifests"
                    loading={this.state.signing}
                  />}

                  <div style={{ marginLeft: 5, fontSize: 16 }}>{`Deliveries: ${count.del}, Pickups: ${count.pick}`}</div>
                </Row>
              </TableListHeader>
              
              {size(regular) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Same Day Pickup"}</div>
                <TableList {...tProps} data={regular.sort(sortByDest)} />
              </div>}
              {size(warehouse) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Warehouse Pickup"}</div>
                <TableList {...tProps} data={warehouse.sort(sortByDestAndCash)} />
              </div>}
              {size(layoverpickup) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Layover Pickup"}</div>
                <TableList {...tProps} data={layoverpickup.sort(sortByDest)} />
              </div>}
              {size(cashreturn) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Cash Return"}</div>
                <TableList {...tProps} data={cashreturn.sort(sortByDest)} />
              </div>}
              {isDrop && size(dropoff) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                {/* <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Dropoffs"}</div> */}
                <TableList {...tProps} data={dropoff.sort(sortByDest)} />
              </div>}
              {/* {size(cashpickup) > 0 && <div className="printNoMarg" css={css(styles.margB10)}>
                <div className="margHeader" css={css(styles.pickTypeHeader)}>{"Cash Pickup"}</div>
                <TableList {...tProps} data={cashpickup.sort(sortByDest)} />
              </div>} */}
            </div>
          )
        })}
      </div>
    )
  }
  print = () => {
    this.setState({ isPrint: true }, () => {
      window.print();
      this.setState({ isPrint: false })
    })
  }
  printManifests = (orders) => {
    const missing = [];
    const manifests = orders.map(o => {
      const man = orderHasManifest(o)
      if (!man.hasFile) { missing.push(o); return null; }
      return {
        ...man,
        url: getManifestUrl(man.file),
      }
    }).filter(f => !!f);

    if (size(missing)) {
      const cont = window.confirm(`These orders are missing manifests:\n\n${missing.map(m => m.orderId).join('\n')}\n\nAre you sure you wish to continue?`);
      if (!cont) { return }
    }
    
    this.setState({ isPrintManifests: true }, async () => {
      try {
        const result = await mergePdfs({ pdfs: manifests.map(m => m.url) })
        const url = getCopiesUrl(result.data);
        window.open(url, '_blank');
      } catch (err) {
        console.error(err);
      } finally {
        this.setState({ isPrintManifests: false })
      }
    })
  }
  filterSchedule = (searchTerm = '') => {
    this.setState({ schedule: filterSchedule(searchTerm, this.state.originalSchedule) })
  }
  onCloseEditModal = shouldUpdate => {
    this.setState({ editModal: undefined }, () => {
      if (shouldUpdate) { this.loadSchedule() }
    })
  }
  render() {
    const {
      loadingSchedule,
      schedule,
      selectedDate,
      editModal,
      showExtras,
    } = this.state;

    const canShowPrint = !this.props.screen.isMobile && schedule && size(schedule) > 0 && !loadingSchedule && selectedDate;

    const { isMobile } = this.props.screen;

    const isDriver = userHasRole(2, this.props.sState);

    return (
      <div className="scheduleWrap" css={css(styles.container)}>
        <div className="no-print" css={css(styles.calContainer, isMobile && `flex-direction: column;`)}>
          <Row style={!isMobile ? { width: '30%', marginRight: 10 } : { marginRight: 10, }}>
            <Input.Search
              placeholder={'Search by Order ID, or Route Name'}
              enterButton
              onSearch={this.filterSchedule}
            />
            <Button icon="sync" style={isMobile ? {} : { marginRight: 10, marginLeft: 10 }} className="no-print" type="primary" onClick={this.loadSchedule} loading={loadingSchedule} />
            {!isDriver && <Button icon="menu-fold" style={isMobile ? {} : { marginRight: 10, marginLeft: 10 }} className="no-print" type="primary" onClick={() => this.setState({ showExtras: !showExtras })} loading={loadingSchedule} />}
          </Row>
          <AdminOrderButton onClose={this.loadSchedule} />
          <DriverOrderButton onClose={this.loadSchedule} />
          <DatePicker 
            css={css(isMobile ? `margin: 5px;` : 'width: 40%; margin: 0 auto;')}
            defaultValue={selectedDate}
            loading={loadingSchedule}
            onChange={this.onSelectDay}
          />

          {canShowPrint && 
            <Button 
              onClick={() => this.setState({ printOnly: undefined }, this.print)} 
              css={css(styles.printBtn)} 
              className="no-print"
              type="primary" 
              icon="printer"
            >
              Print
            </Button>}
        </div>

        <div css={css(styles.schedContainer)}>
          {this.renderSchedule()}
          {/* {this.renderWarehouse()} */}
        </div>
        <OrderModal editModal={editModal} onClose={this.onCloseEditModal} />
        <MapModal {...this.state.map} onClose={() => this.setState({ map: {} })} />
      </div>
    )
  }
}

export const Schedule = ScreenComponent(withRouter(ScheduleComponent));
